import { AppProps } from 'next/app';
import { Roboto_Mono } from 'next/font/google';
import 'reactflow/dist/style.css';
import './styles.scss';
import { ClerkProvider } from '@clerk/nextjs';
import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Script from 'next/script';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-multi-email/dist/style.css';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { CookiesProvider } from 'react-cookie';

const enablePosthog =
  process.env['NEXT_PUBLIC_ENABLE_POSTHOG'] === '1' ||
  process.env.DOPPLER_ENVIRONMENT === 'production' ||
  process.env['NEXT_PUBLIC_APP_ENV'] === 'production';

// Check that PostHog is client-side (used to handle Next.js SSR)
if (enablePosthog && typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.DOPPLER_ENVIRONMENT === 'development') posthog.debug();
    },
  });
}
const handleRouteChange = () => posthog?.capture('$pageview');

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => {
  handleRouteChange();
  NProgress.done();
});
Router.events.off('routeChangeComplete', () => {
  handleRouteChange();
});
Router.events.on('routeChangeError', () => {
  NProgress.done();
});
NProgress.configure({
  showSpinner: false,
  template:
    '<div class="nprogressClass"><div class="bar" role="bar"><div class="peg"></div></div><div class="splash"><div class="logoWrapper"><div class="logoContainer"><div class="logoRelative"><div class="logo"/><div class="ping"/></div></div></div></div></div>',
});

const robotoMono = Roboto_Mono({ subsets: ['latin'] });

const publishableKey = process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY;

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <PostHogProvider client={posthog}>
        <ClerkProvider publishableKey={publishableKey}>
          <style jsx global>
            {`
              * {
                font-family: ${robotoMono.style.fontFamily};
              }
            `}
          </style>
          <main>
            <div>
              <Component {...pageProps} />
            </div>
          </main>
          <Script id="intercomScript">
            {`
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wku86xdr';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          `}
          </Script>
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
       (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
       new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
       j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
       'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
       })(window,document,'script','dataLayer','GTM-WNMCNT9K');
        `}
          </Script>

          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop
            closeOnClick={false}
            rtl={false}
            draggable
            toastClassName={() =>
              'bg-zinc-800 border border-zinc-700 relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer mt-10'
            }
            bodyClassName={() =>
              'text-xs text-white p-2 fill-white flex items-center gap-1'
            }
          />
        </ClerkProvider>
      </PostHogProvider>
    </CookiesProvider>
  );
}

export default CustomApp;
