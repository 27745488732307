var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  environment: process.env.DOPPLER_ENVIRONMENT,
  dsn: SENTRY_DSN,
  enabled: !process.env['IGNORE_SENTRY'],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate:
    process.env.DOPPLER_ENVIRONMENT === 'production' ? 1.0 : 0.1,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
